html {
  scroll-behavior: smooth;
  transition: all 2s ease-in-out;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  width: 100%;
  transition: all 2s ease-in-out;
}
.mark:first-of-type:after {
  content: "";
  position: absolute;
  width: 80px;
  height: 12px;
  top: 110px;
  left: 12%;
  z-index: 1;
  -webkit-transform: translate(-45px);
  -ms-transform: translate(-45px);
  transform: translate(-45px);
  background-color: yellow;
  -webkit-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url(./assets/headline-curve.svg);
  mask-image: url(./assets/headline-curve.svg);
}
mark {
  background-color: transparent;
  color: white;
}
.mark:last-of-type:after {
  content: "";
  position: absolute;
  width: 80px;
  height: 12px;
  bottom: 155px;
  left: 80%;
  z-index: 1;
  -webkit-transform: translate(-45px);
  -ms-transform: translate(-45px);
  transform: translate(-45px);
  background-color: yellow;
  -webkit-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url(./assets/headline-curve.svg);
  mask-image: url(./assets/headline-curve.svg);
}

.slick-dots li button::before {
  font-size: 10px !important;
}

/* .slick-dots li.slick-active button::before {
  color: blue;
} */
.animated {
  height: 0;
  position: fixed;
  width: 100%;
  animation: header 0.5s ease-in-out;
  z-index: 10;
  transition: all 0.5s ease-in-out;
}
.not {
  width: 100%;
  height: 100%;
  position: relative;
}
@keyframes header {
  0% {
    height: 0px;
    opacity: 0;
  }
  20% {
    height: 20px;
  }
  40% {
    height: 40px;
    opacity: 0.5;
  }
  60% {
    height: 60px;
  }
  80% {
    height: 100px;
  }
  100% {
    height: 150px;
    opacity: 1;
  }
}
